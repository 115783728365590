import { useEffect, useState } from 'react';

export const useHiddenHeader = () => {
  const [show, setShow] = useState(true);
  const [isOnTop, setIsOnTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleSetIsOnTop = () => {
    if (window.scrollY < 250) {
      setIsOnTop(true);
    } else {
      setIsOnTop(false);
    }
  };

  const handleSetShow = () => {
    const minScrollLength = 150;
    setScrollPosition((prev) => {
      if (Math.abs(prev - window.scrollY) < minScrollLength) return prev;
      setShow(prev > window.scrollY);
      return window.scrollY;
    });
  };

  const control = () => {
    handleSetIsOnTop();
    handleSetShow();
  };

  useEffect(() => {
    window.addEventListener('scroll', control);
    return () => {
      window.removeEventListener('scroll', control);
    };
  }, []);

  return { show, isOnTop, scrollPosition };
};
