import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import HappyPerson from '@/assets/happy-person.webp';
import { addToNewsletter } from '@/services/blog';

import Button from '../Button';
import TextInputField from '../Form/Fields/TextInputField';
import Text from '../Text';

const NewsletterForm = () => {
  const { register, handleSubmit, reset } = useForm<InferType<typeof schema>>({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });
  const { mutate } = useMutation({
    mutationFn: addToNewsletter,
    mutationKey: ['addToNewsletter'],
    onSuccess: () => {
      reset();
      toast.success(
        'We have sent an email to confirm your subscription to the newsletter'
      );
    },
  });
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-5xl flex-row gap-6 rounded-lg bg-neutral-100 p-6">
        <div className="hidden flex-1 md:flex">
          <Image src={HappyPerson} alt="" />
        </div>
        <div className="flex flex-4 flex-col justify-center gap-4 text-neutral-1000">
          <Text variant="h3" className="text-h5 font-semibold md:text-h3">
            Don`t miss our hottest news!
          </Text>
          <Text variant="h5" className="!text-body-small md:text-h5">
            Get the freshest AI HR news from MindPal delivered straight to your
            inbox
          </Text>
          <form
            className="flex flex-col gap-10 md:flex-row"
            onSubmit={handleSubmit(({ email }) => mutate(email))}
          >
            <TextInputField
              {...register('email')}
              variant="light"
              placeholder="Your email goes here"
            />
            <Button
              variant="primaryLight"
              size="long"
              type="submit"
              className="text-neutral-100"
            >
              Submit
            </Button>
          </form>
          <Text variant="body-caption">
            By submitting, you accept our{' '}
            <a
              href="https://mindpal.co/privacy-policy/"
              target="_blank"
              className="text-secondary-700"
            >
              Privacy Policy
            </a>
            .
          </Text>
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;

const schema = object({
  email: string().email().required(),
});
